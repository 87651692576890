import * as Icons from "@phosphor-icons/react";
import { checkTasksReadyToDisplay } from "selectors/tasks";
import { getGlobalStats, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { TASKS } = UiRoutes;

const TasksCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const tasksReadyToDisplay = useSelector(checkTasksReadyToDisplay);

  const { id: userId } = useSelector(getUserData);

  const {
    tasks: {
      active: { count = 0 } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${TASKS}?targetUser=${userId}`}
      theme="success"
      mainText={count}
      secondaryText={uiTexts.tasks.toLowerCase()}
      description={messages.dashboardTasks}
      icon={Icons.CheckSquareOffset}
      loading={!globalStats.tasks || !tasksReadyToDisplay} />
  );
};

export default React.memo(TasksCounter);
