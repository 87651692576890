import * as Icons from "@phosphor-icons/react";
import { getGlobalStats, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import DataConstants from "const/DataConstants";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { DOCUMENTS } = UiRoutes;

const { STATUSES: { TO_REVIEW } } = DataConstants;

const DocumentsAwaitingReviewCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const {
    documents: {
      toReview: { count = 0 } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${DOCUMENTS}?status=${TO_REVIEW}`}
      theme="primary"
      mainText={count}
      secondaryText={uiTexts.documents.toLowerCase()}
      description={messages.dashboardDocsNeedReview}
      icon={Icons.FileText}
      loading={!globalStats.documents} />
  );
};

export default React.memo(DocumentsAwaitingReviewCounter);
